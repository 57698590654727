import { replace, round } from "lodash";
import numeral from "numeral";
import { WALLET_TYPES } from "./apis";

// ----------------------------------------------------------------------

export function fCurrency(
  number,
  showText = false,
  showKobo = true,
  isVisible = true,
  currency = "NGN"
) {
  const walletType = (WALLET_TYPES.filter((type) => type.value === currency)
    .length > 0 &&
    WALLET_TYPES.filter((type) => type.value === currency)[0]) || {
    value: "NGN",
    symbol: "₦",
  };
  return `${showText ? walletType.value : walletType.symbol} ${
    isVisible ? numeral(number).format(showKobo ? "0,0.00" : "0,0") : "****.**"
  }`;
}

export function fCurrencyAbb(number) {
  return `₦${numeral(number).format("0a")}`;
}

export function fPercent(number) {
  return numeral(number / 100).format("0.0%");
}
export function fPercentOnly(number, total) {
  const percent = (number / total) * 100;
  return Number.isNaN(percent) ? 0 : round(percent);
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format("0.00a"), ".00", "");
}

export function fData(number) {
  return numeral(number).format("0.0 b");
}

export function isNumeric(str) {
  if (typeof str !== "string" && typeof str !== "number") return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
}

export const fRange = (start, stop, step) => {
  if (stop < start) {
    return Array.from(
      { length: (start - stop) / step + 1 },
      (_, i) => start - i * step
    );
  }
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  );
};
